import "./ProductContainer.css"
import "./CurrentProductContainer.css"
import {useCms} from "../../../context/CmsContext";
import CmsHelper, {PickProgramPageHelper} from "../../Utilities/CmsHelpers";

export default function CurrentProductContainer({product}) {
    const {pickProgramData} = useCms();
    const programInfo = PickProgramPageHelper.getProgramInfo(PickProgramPageHelper.getPrograms(pickProgramData, CmsHelper.DEFAULT));
    const cmsData = PickProgramPageHelper.getProgram(programInfo, product.displayName)[0];
    let usps = [
        ...(cmsData.infUsps?.usp || []),
        ...(cmsData.usps?.usp || [])
    ];
    const rowCount = Math.ceil(usps.length / 2);
    const columns = Array.from({ length: 2 }, () => []);

    usps.forEach((item, index) => {
        const colIndex = Math.floor(index / rowCount);
        columns[colIndex].push(item);
    });
    return (<div className={`pick-product-wrapper current`}
                    id={product.displayName}>
                <div className={"pick-product-upper"}>
                    <span className={"pick-current-product-title"}>Έχεις ήδη το πρόγραμμα</span>
                    <span className={"pick-product-title"}
                          style={{backgroundColor: cmsData.displayName.colorCode+"4D"}}>{cmsData?.title}</span>
                </div>
                <div className={"pick-product-lower"}>
                    <div className={"pick-product-usps"}>
                        {columns.map((col, colIndex) => (
                            <ul key={colIndex}>
                                {col.map((item, itemIndex) => {
                                    return <li key={itemIndex}>
                                        {item.value}
                                    </li>
                                })}
                            </ul>
                        ))}
                    </div>
                </div>
    </div>)
}